import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

function ContactPage() {
  return (
    <Layout>
      <SEO title='Contact Us' />
      <div className="relative bg-gradient-to-r from-altblue-light to-altblue">
        <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-6xl">Get in touch</h1>
          <p className="mt-6 text-xl text-gray-100 max-w-3xl">
          Thanks for your interest. If you have any questions or press inquiries, please use the information below to contact us.
          </p>
        </div>
      </div>
      <div className='flex flex-col items-center justify-center w-full py-48 bg-gray-50'>
        <div className="px-16 py-20 rounded-lg shadow-lg bg-white text-center">
          <p className="text-center text-4xl font-bold">Attn: Press Request</p>
          <p className="text-center my-8 text-xl">
            1100 South Ocean Blvd
            <br />
            Palm Beach, FL 33480
          </p>
          <a
            href='mailto:press@tmtgcorp.com?subject=TMTG Press Request'
            className='inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'>
            press@tmtgcorp.com
          </a>
        </div>
      </div>
    </Layout>
  )
}

export default ContactPage
